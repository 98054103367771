import {
  CustomForm,
  CustomFormVersion,
  CustomTextBoxFormField,
} from '@tremaze/shared/feature/custom-forms/types';
import { Meta } from '@tremaze/shared/models';

export abstract class CustomFormFixtures {
  static generateCustomFormField = (id: string) =>
    new CustomTextBoxFormField(
      id,
      new Meta(),
      `Field ${id}`,
      `Field ${id}`,
      true,
      0,
      `${id}`,
    );

  static generateCustomFormVersion = (id: string) => {
    const fields = Array.from({ length: 5 }, (_, i) =>
      CustomFormFixtures.generateCustomFormField(i.toString()),
    );

    return new CustomFormVersion(fields, id, {
      id,
      name: `Form ${id}`,
      institutions: [],
      hasHtmlTemplate: false,
      hasCSVTemplate: false,
    });
  };

  static generateCustomForm = (id: string): CustomForm =>
    new CustomForm(
      id,
      new Meta(),
      `Form ${id}`,
      true,
      CustomFormFixtures.generateCustomFormVersion(id),
    );
}
